.activity__top__header,
.activity__header__buttons {
  flex-wrap: wrap;
}
.activity__top__header h4 {
  font-size: 24px;
}
.activity__header__buttons span {
  font-size: 16px;
}
.activity__top__header .add_activity_btn {
  background: var(--accent-color);
  height: 40px;
  width: 175px;
  font-size: 14px;
}
.add_activity_btn span {
  height: 26px;
  width: 26px;
}
.activity__top__header .add_activity_btn:hover {
  background: var(--dark_blue);
}
.activity__top__header .add_activity_btn:hover i {
  color: var(--dark_blue);
}
.activity__header__bottom {
  overflow: auto;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--gray_dark);
}
.activity__header__bottom span {
  white-space: nowrap;
  font-size: 14px;
}
.activities_buttons_groups {
  flex-wrap: wrap;
}
.activities_buttons_groups .button_group {
  height: 40px;
  overflow: hidden;
}
.activities_buttons_groups .button_group .button_group_single_btn {
  font-size: 14px;
}
.activities_buttons_groups .button_group .button_group_single_btn svg {
  fill: var(--dark-blue-65);
}
.activities_buttons_groups .button_group .button_group_single_btn {
  border: 1px solid var(--gray_dark);
  transition: 0.3s linear;
  height: 100%;
  cursor: pointer;
}
.icon_buttons .button_group_single_btn {
  width: 40px;
}
.activities_buttons_groups .button_group .button_group_single_btn:hover i,
.activities_buttons_groups .button_group .button_group_single_btn.active i {
  color: var(--white);
}

.activities_buttons_groups .active_task {
  color: var(--white);
  background: var(--accent-color);
  border-color: var(--accent-color);
}

.activities_buttons_groups .button_group .button_group_single_btn:hover {
  color: var(--white);
  background: var(--accent-color);
  border-color: var(--accent-color);
}
.activities_buttons_groups .button_group .button_group_single_btn:hover svg {
  fill: var(--white);
}

/* Activity calendar & list Tab */
.activity_calendar_list_tab .tabs {
  height: 40px;
  border-radius: 5px;
  width: fit-content;
}

.activity_calendar_list_tab .tabs .tab a {
  color: var(--dark_blue);
  background: var(--gray_light);
  text-transform: capitalize;
}
.activity_calendar_list_tab .tabs .tab a:first-child {
  border-right: 1px solid var(--white);
}
.activity_calendar_list_tab .tabs .tab a.active_tab,
.activity_calendar_list_tab .tabs .tab a:hover,
.activity_calendar_list_tab .tabs .tab a.active_tab {
  background: var(--dark_blue);
  color: var(--white);
}
.activity_calendar_list_tab .tabs .indicator {
  display: none;
}
.activity_select_field {
  height: 40px;
  border: 1px solid var(--gray);
  border-radius: 5px;
}
.activity_select_field.tasks {
  width: 124px;
}
.activity_select_field.columns {
  width: 112px;
  background: url(../assets/img/column.png);
  background-repeat: no-repeat;
  background-position: 7px center;
  padding-left: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.activity_select_field.actions {
  width: 93px;
}
.activity_select_field.selected_users {
  width: 158px;
}
.activity_select_field:focus {
  outline: 0;
}
.activity_select_field,
.activity_select_field option {
  font-size: 14px;
  color: var(--gray);
  font-family: var(--poppins);
}

.calendar_info_item_checkbox
  input[type="checkbox"].filled-in
  + span:not(.lever):after {
  border-radius: 5px !important;
  border-color: rgba(60, 127, 243, 0.35) !important;
  background: rgba(60, 127, 243, 0.2);
  height: 18px;
  width: 18px;
  margin-top: 3px;
}

.calendar_info_item_checkbox
  input[type="checkbox"].filled-in
  + span:not(.lever) {
  padding-left: 25px;
}
.calendar_info_item_checkbox
  input[type="checkbox"].filled-in:checked
  + span:not(.lever):after {
  background: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}
.calendar_info_item_checkbox span {
  font-size: 14px !important;
}

@media (max-width: 1040px) {
  /* .header_bottom_middle {
    margin-left: 0;
    margin-top: 50px;
  } */
}

.activity_calendar_list_tab .tabs .tab a.active_tab {
  background: var(--dark_blue);
  color: var(--white);
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  flex-direction: row !important;
  display: inline-flex !important;
  border-radius: 0px !important;
  width: 100%;
}

#calendar__tab .rbc-event .rbc-event-content {
  width: auto;
  flex: unset;
  display: inline-flex;
  font-size: 12px;
}

.rbc-event-label {
  font-size: 8px;
  letter-spacing: 0;
}

#calendar__tab .rbc-event .rbc-event-content .material-icons {
  font-size: 11px;
}

.rbc-day-slot .rbc-event-label {
  line-height: 24px;
  font-size: 11px;
}

.time_picker_preview {
  height: 45px !important;
}

text-blue {
  color: blue;
}

.data-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.data-loading .modal-content {
  height: 100%;
}

.data-loading .row {
  height: 100%;
}

.data-loader-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 40px);
}

.rbc-day-slot .rbc-event {
  position: static;
}

.activity_calendar_list_tab .tabs .tab a.active_tab,
.activity_calendar_list_tab .tabs .tab a:hover,
.activity_calendar_list_tab .tabs .tab a.active_tab,
.activities_buttons_groups .button_group .button_group_single_btn:hover
{
  background: var(--accent-color) !important;
}