*, *::before, *::after {
    box-sizing: inherit;
}
.hide{
    display: none;
}
.show{
    display: block;
}
.timeslot-calender-main{
    background: #fff;
    position: relative;
    z-index: 0;
}
.timeslot-wrapper{
    /*min-width: 320px;*/
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 50px 0px;
    transition: all 0.22s ease-out;
}
.timeslot_leftside_calendar{
    height:calc(100vh - 100px);
}
.pt-50{
    padding-top:50px
}
.timeslot-wrapper .timeslot-col-3.hide{
    width: auto;
}
.timeslot-wrapper .timeslot-col-4{
    width: 45%;
}
.timeslot-wrapper .timeslot-col-5{
    width: 55%;
}
.timeslot-inner{
    margin: 0 auto;
}
.timeslot-branding{
    position: relative;
    height: 5px;
    background-color: #666A73;
    z-index: 1;
}
.calander-title-leftside{
font-weight: 700;
margin-bottom: 16px;
font-size: 30px;
margin-top:8px;
}
.calender-leftside{
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.timeslot-branding .timeslot-badge{
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    overflow: hidden;
    visibility: hidden;
}
.timeslot-branding .timeslot-badge a{
    display: block;
    visibility: visible;
    padding: 85px 0 8px 0;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    background-color: #666A73;
    text-decoration: none;
    color: #fff;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: rotate(45deg) translate(35%, -86%);
    transform: rotate(45deg) translate(35%, -86%);
    transition: background-color 0.2s;
}
.timeslot-inner{
    /*margin: 0 95px 0 145px;*/
    margin: 0 50px;
    transition: all 0.22s ease-out;
}





@media (min-width: 1000px){
   
    .timeslot-wrapper.has-selected-day .timeslot-inner{
        margin: 0 30px 0 70px;
    }
}




.timeslot-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.timeslot-summary .timeslot-profile-info .timeslot-profile-info-name{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #7d7b7b;
}
.timeslot-summary .timeslot-profile-info .timeslot-profile-info-event-type-name{
    font-size: 26px;
    font-weight: 600;
    color: #4D5055;
    margin-bottom: 12px;
}
.outer-border-slot{
border:1px solid #aeaeae;
padding:16px 24px;
border-radius: 4px;
height:510px;

}

.timeslot-details .timeslot-details-item span{
    font-weight: bold;
    color:#333;
}
.timeslot-details-item {
    display: flex !important;
    align-items: center !important;
    gap: 8px;
}


.timeslot-box{
    position: relative;
    width: 100%;
}
.timeslot-box .timeslot-title h4{
    font-size: 16px;
    margin-bottom: 10px;
    /* height: 38px; */
    /* line-height: 38px; */
    color:#666A73;
}
.timeslot-items .timeslot-list{
    margin-bottom: 10px;
    float: left;
    display: block;
    width: 100%;
}
.timeslot-items .timeslot-list span{
    width: 100%;
    display: block;
    border: 1px solid #c0d5e4;
    padding: 12px 10px;
    background: transparent;
    text-align: center;
    font-size: 16px;
    border-radius: 3px;

}
.timeslot-items .timeslot-list span:hover{
    cursor: pointer;
    border-color: #0b94ea;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.timeslot-col-3,.timeslot-col-4,.timeslot-col-5,.timeslot-col-8,.timeslot-col-12{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.timeslot_items_v2_v4 .scrollbar-container{
    padding-right: 12   px;
}
.timeslot-item-list{
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.timeslot-items .timeslot-list .timeslot-confirm {
    line-height: 24px;
    margin-left: 3%;
    width: 48.5%;
    background-color: RGB(0, 162, 255);
    background-color: #48a2fb;
    color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
}
.timeslot-items .timeslot-list.is-selected .timeslot-confirm {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.timeslot-items .timeslot-list.is-selected span{
    float: left;
    cursor: pointer;
}
.timeslot-items .timeslot-list.is-selected {
    margin-bottom: 10px;
    clear: both;
    width: 100%;
    float: left;
    display: block;
}
.timeslot-items .timeslot-list.is-selected .timeslot-time{
    width: 48.5%;
    background-color: #666a73;
    border-color: #666a73;
    color: white;
}
.timeslot-items .timeslot-list.is-selected .timeslot-confirm{
    width: 48.5%;
    color: white;
}
@media (min-width: 768px){
    .timeslot-wrapper.has-selected-day .timeslot-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .timeslot-wrapper.has-selected-day .timeslot-col-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .timeslot-wrapper.has-selected-day .timeslot-col-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .timeslot-wrapper.has-selected-day .timeslot-col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .timeslot-wrapper.has-selected-day .timeslot-col-3{
        flex: 0 0 25%;
        max-width: 25%;
    }
}

#schedule-temp-height{
    position: relative;
    height: 500px;
}

.back-arrow a:hover {
    text-decoration: none;
    display: inherit;
    color: #fff;
    background: #08be7c;
}
.back-arrow a {
    line-height: 40px;
    font-size: 32px;
    text-align: center;
    transition: all 0.4s ease-in-out;
    background: transparent;
    display: inline-block;
}


.timeslot__wr__v2__right .timeslot-body .react_int_tel_input_wr input {
    border: 1px solid #ddd;
    border-radius: 5px !important;
    padding: 0 10px 0 47px;
}
.timeslot__wr__v2__right .timeslot-body .confirm_form_templete_input {
    border: 1px solid #ddd;
    border-radius: 5px !important;
    padding: 10px;
}
.timeslot__wr__v2__right .timeslot-body .confirm_form_templete_input:focus,
.timeslot__wr__v2__right .timeslot-body .react_int_tel_input_wr input:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
    border-color: #ddd !important;
}



@media (max-width: 1024px){
    .timeslot_calender_main_wrapper_v2 .timeslot-wrapper{
        padding: 0 !important;
    }
    .timeslot_calender_main_wrapper_v2 .timeslot__wr__v2 .timeslot__wr__v2__left{
        width: 100% !important;
       
    }
    .timeslot_calender_main_wrapper_v2 .timeslot__wr__v2 .timeslot__wr__v2__right{
        width: 100% !important;
        margin-top: 20px !important;
    }
}


@media screen and (max-width: 375px) {
    .timeslot_calender_main_wrapper_v2 .timeslot-wrapper{
        padding: 0 !important;
    }
    .timeslot_calender_main_wrapper_v2 .timeslot-inner {
        margin: 0 20px 0 20px !important;
    }
    .CalendarModule-container-parent-class .back-arrow1_custom {
        margin-bottom: 58px;
        display: block;
    }
    .back-arrow1_custom a {
        width: 30px !important;
        height: 30px !important;
        left: 10px !important;
        top: 12px;
    }
    .back-arrow1_custom a svg{
        width: 17px;
    }
}


@media screen and (max-width: 1060px) {
    .timeslot_calender_main_wrapper_v2 .has-selected-day .timeslot-inner {
        margin: 0 92px;
    }
    .timeslot_calender_main_wrapper_v2 .has-selected-day .timeslot__wr__v2__left{
        flex: 0 0 28.33333% !important;
    }
    .timeslot_calender_main_wrapper_v2 .has-selected-day .timeslot__wr__v2__right{
        flex: 0 0 46.66667% !important;
        max-width: 46.66667% !important;
    }
}

@media screen and (max-width:991px) {
    .timeslot_calender_main_wrapper_v2 .has-selected-day .timeslot__wr__v2__left{
        flex: 0 0 100% !important;
        max-width: 100%;
    }
    .timeslot_calender_main_wrapper_v2 .has-selected-day .timeslot__wr__v2__right{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .timeslot_calender_main_wrapper_v2 .has-selected-day #timeslot-show{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .timeslot_calender_main_wrapper_v2 .time_solt_title_v2_title_wr{
        margin-top: 16px;
    }
}



























.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}

.react-datepicker__month .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
    -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 200px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__month--disabled {
    color: #ccc;
    pointer-events: none;
}

.react-datepicker__month--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text {
    cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range {
    background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    background-color: #216ba5;
}

.react-datepicker__month-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
}

.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}
/********************************************
Custom css
******************************************/

.react-datepicker{
    width:100%;
}
.react-datepicker__month-container {
    width: 100%;
}
.react-datepicker__month {
    padding: 15px 0px;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled {
    color: #ccc!important;
}
.react-datepicker__day-name, .react-datepicker__day {
    width: 50px;
    line-height: 50px;
    font-size: 16px;
    color:#666a73;
}
.react-datepicker__day--today, .react-datepicker__month-text--today{
    position: relative;
}
.react-datepicker__day--today:after{
    border-radius: 50%;
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background: #ccc;
    left: 24px;
    bottom: 9px;
}
.react-datepicker__day.react-datepicker__day--selected {
    color: #fff;
}
.tsc-footer{
    padding: 0!important;
    text-align: left!important;
}
.tsc-form-group {
    margin-bottom: 20px!important;
}
.tsc-form-group1 {
    margin-bottom: 10px!important;
}
.tsc-form-control{
    border-radius: inherit!important;
}
.back-arrow a{
    font-weight: bold;
    font-size: 50px;
    width: 45px;
    height: 45px;
    line-height: 40px;
    border-radius: 50%;
}
.back-arrow1_custom a{
    font-weight: bold;
    font-size: 50px;
    width: 45px;
    height: 45px;
    line-height: 40px;
    border-radius: 50%;
    background-color: var(--dark-blue-10);
    display: flex;
    justify-content: center;
    align-items: center;
    left: -50px;
    position: absolute;
}

span.back-arrow {
    left: -50px;
    position: absolute;
}
.pl-0{
    padding-left: 0px!important;
}
.tsc-col label{
    font-size: 14px;
    font-weight: 600;
}

/****Confirmation page css****/
.confirm-wrapper{
    margin: 25px auto 0 auto;
    max-width: 450px;
}
.confirm-wrapper h2{
    font-size: 20px;
}
.confirm-wrapper .header {
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 1px solid #e0dfe3;

}
.confirm-wrapper .header span{
    color: #656565;
}

.confirm-wrapper .confirm-body ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.confirm-wrapper .confirm-body ul li h2{
    display: inline-block;
}
.confirm-wrapper .confirm-body ul li {
    margin-bottom: 10px;
    font-size: 15px;
    color: #5e5f63;
}
.confirm-wrapper .confirm-body ul li span{
    margin-right: 10px;
    font-size: 20px;
    color: #666a73;
}
.confirm-wrapper .confirm-body .details-content{
    margin-bottom: 20px;
    text-align: center;
}
.confirm-wrapper .confirm-footer{
    padding-top: 30px;
    text-align: center;
    border-top: 1px solid #e0dfe3;
}
.confirm-wrapper .confirm-footer .custom-link {
    border-radius: 5px;
    padding: 12px 30px;
    background:#666A73;
    color:#fff;
    transition: all 0.5s ease-in;
}
.confirm-wrapper .confirm-footer .custom-link:hover{
    text-decoration: none;
    background: #007bff;
    color: #fff;
}
.confirm-wrapper .confirm-footer .custom-link span{
    font-size:20px;
}
.invalid-calender{
    text-align: center;
    padding:30px 0px;
}
.invalid-calender h1{
    font-size:25px;
    color: #989898;
}

@media screen and (max-width: 500px) {
    body.calender-page .timeslot-summary .timeslot-profile-info .timeslot-profile-info-event-type-name{
        text-align: center!important;
    }
    .loge-col-wrap{
        top: 5px;
    }
    .timeslot-items .timeslot-list span{
        font-weight: bold;
    }
    .react-datepicker__day-name, .react-datepicker__day{
        width: 12%!important;
        font-size: 12px!important;
        line-height: 30px!important;
    }
}

.react-datepicker__day{
    color: #6acab7 ;
    font-weight: 905;
}

.react-datepicker__day--weekend{
    color: #6acab7;
}

.react-datepicker__day.react-datepicker__day--weekend{
    color: #6acab7 !important;
}

.react-datepicker__day.react-datepicker__day--disabled{
    color: #a09f9f !important;
}

.calendar__menu {
    top: unset !important;
    bottom: 100%;
}

.tsc-error-message {
    color: red !important;
}

.calendar-widget-description
{
    text-align: justify;
}


