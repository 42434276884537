#task_appointment_modal {
  top: 0% !important;
  max-width: 556px;
  height: calc(100vh - 20px);
  max-height: calc(100vh - 20px);
}
#task_appointment_modal:focus{
  outline: none;
}
#task_appointment_modal,
#task_appointment_modal .tabs {
  overflow-x: hidden;
}
#task_appointment_modal .tabs .indicator {
  display: none;
}
#task_appointment_modal .tabs .tab a {
  text-transform: capitalize;
  color: var(--dark_blue);
  /* background: var(--gray_light); */
}
#task_appointment_modal .tabs .tab a svg {
  fill: var(--dark_blue);
}
#task_appointment_modal .tabs .tab a:hover svg,
#task_appointment_modal .tabs .tab a.active svg {
  fill: var(--light_blue);
}
#task_appointment_modal .tabs .tab a:hover,
#task_appointment_modal .tabs .tab a.active {
  color: var(--light_blue);
  /* color: var(--white); */
}
#task_appointment_modal .single_modal_tab_task {
  border-left: 5px solid transparent;
  transition: 0.3s ease-in-out;
  border-bottom: 1px solid var(--gray_dark);
}
.single_modal_tab_task .status {
  font-size: 16px;
}
.single_modal_tab_task .time,
.single_modal_tab_task label .comment {
  font-size: 14px;
}

#task_appointment_modal .single_modal_tab_task:hover {
  border-left-color: var(--light_green);
  background: rgba(60, 127, 243, 0.1);
}
#task_appointment_modal .tab_task_round_icon {
  border: 1px solid var(--light_blue);
  border-radius: 50%;
  display: inline-block;
  height: 18px;
  width: 18px;
}
#task_appointment_task_tab
  input[type="checkbox"].filled-in
  + span:not(.lever):after {
  border-radius: 5px !important;
  border-color: var(--light_blue) !important;
}
#task_appointment_task_tab > p {
  font-size: 14px;
}
#task_appointment_task_tab input[type="checkbox"].filled-in + span:not(.lever) {
  padding-left: 30px;
}
#task_appointment_task_tab
  input[type="checkbox"].filled-in:checked
  + span:not(.lever):after {
  background: var(--light_blue) !important;
}

.text-blue {
  color: blue;
}

.custom-cursor-pointer{
  cursor: pointer;
}
.calendar-task-appointment-close{
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex !important;
  background: var(--red);
  color: #fff;
  font-weight: 500;
}



.task__appointment__modal__extra .single_modal_top__wrapper{
  grid-gap: 12px;
}

.task__appointment__modal__extra .calendar__activity__modal__date{
    background: var(--gray_dark);
    margin: 12px 0 0 0;
    padding: 5px;

}
.task__appointment__modal__extra .calendar__activity__modal__appointment{
  
}
.task__appointment__modal__extra  .calendar__activity_modal_tab_task{
  background: #fff !important;
  border: 0 !important;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px !important;
}


.task__appointment__modal__extra .single_modal_tab_task{
  background: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 5px;
}
.task__appointment__modal__extra  #task_appointment_task_tab{
  padding: 12px !important;
}
.task__appointment__modal__extra .single_modal_tab_task +.single_modal_tab_task{
  margin-top: 8px !important;
}
.task__appointment__modal__extra .single_modal_tab_label{
  align-items: flex-start;
}
.task__appointment__modal__extra .calendar__activity__modal__appointment .calendar__overdue__completed__status{
  width: 100% !important;
}
.task__appointment__modal__extra .calendar__activity__modal__appointment .calendar__overdue__completed__status svg{
  width: 15px;
}
.task__appointment__modal__extra .calendar__activity__modal__appointment .calendar__overdue__completed__status .status{
  font-size: 14px;
}
.task__appointment__modal__extra .calendar__activity__modal__appointment .cl__activity__modal__checkbox__text{
  cursor: pointer;
  margin-top: 10px !important;
  margin-left: -2px !important;
}
.task__appointment__modal__extra .single_modal_tab_task .single_modal_tab_undo__p{
  font-size: 14px;
}
.task__appointment__modal__extra .single_modal_tab_task .single_modal_tab_undo_a{
  font-size: 14px;
}


.task__appointment__modal__extra .task__appointment__modal__extra_container{
  position: relative;
}


.task__appointment__modal__extra .task__appointment__modal__extra_close{
  position: absolute;
  right: 20px;
  top: 20px;
  background: var(--red);
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;

}
.task__appointment__modal__extra .task__appointment__modal__extra_title{
  font-size: 20px;
  margin: 0;
  padding: 20px;
}
.task__appointment__modal__extra .task__appointment__modal__extra_ul{
    display: flex;
    padding: 0 20px !important;
    border-bottom: 1px solid #ddd;
    justify-content: center;
    align-items: center;
}
.task__appointment__modal__extra .task__appointment__modal__extra_ul li.tab a{
  cursor: pointer;
  position: relative;
  padding: 0 28px !important;
}
.task__appointment__modal__extra .task__appointment__modal__extra_ul li.tab a::after{
  content: " ";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--light_blue);
  opacity: 0;
}
.task__appointment__modal__extra .task__appointment__modal__extra_ul li.tab a:hover::after{
  opacity: 1;
}
.task__appointment__modal__extra .task__appointment__modal__extra_ul li.tab a.active::after{
  opacity: 1;
}


