body {background: #F5F7F9;}
.background-calendar-preview {background: #FFFFFF; padding-bottom: 100px}
/* calendar section start */
.calendar_section {
    min-height: calc(100vh - 120px);
    margin: 5px 0 20px;
}
.calendar_section .page_heading {
    border-bottom: 2px solid #E5E8EF;
    justify-content: space-between;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
}
.calendar_section .page_heading h4 {
    font-weight: 500;
    color: #133159;
    margin: 0;
    font-size: 24px;
    line-height: 36px;
}
.calendar_section .page_heading a {
    justify-content: center;
    background: var(--accent-color) !important;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 227px;
    padding: 0 12px;
    height: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
 }
.calendar_section .page_heading a svg {margin-right: 8px;}

.create_calendar_widget-overlay {
    position: absolute !important;
    left: -20px;
    top: -20px;
    width: calc(100% + 40px);
}
.create_calendar_widget .modal-content {padding: 0 !important;}
.create_calendar_widget {
    border-radius: 10px 0 0 10px;
    top: 80px !important;
    right: 0 !important;
    max-width: 720px;
    width: 100%;
    padding: 0;
    left: auto;
    background: #fff;
}
.create_calendar_widget .header {
    border-bottom: 1px solid #E5E8EF;
    justify-content: space-between;
    padding: 20px 20px 12px;
    display: flex;
}
.create_calendar_widget .header h5 {
    line-height: 36px;
    color: #133159;
    font-weight: 500;
    margin: 0;
    font-size: 24px;
}
.create_calendar_widget .modal_article > .row {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 0;
}
.create_calendar_widget .modal_article {
    border-bottom: 1px solid #E5E8EF;
    padding: 20px;
}
.create_calendar_widget .modal_article label {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
    margin: 0;
    font-weight: 500;
}
.create_calendar_widget .modal_article input:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #E5E8EF;
}
.create_calendar_widget .modal_article input::placeholder {color: #A6ACBE;}
.create_calendar_widget .modal_article input {
    border: 1px solid #E5E8EF;
    font-family: var(--poppins);
    line-height: 40px;
    padding: 8px 20px;
    max-width: 210px;
    height: 40px;
    width: 100%;
    margin: 10px 0;
    font-size: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    color: rgba(19, 49, 89, 0.65);
}
.create_calendar_widget .modal_footer {
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    display: flex;
}
.create_calendar_widget .modal_footer .modal_btn svg {
    vertical-align: middle;
    margin: -3px 5px 0 0;
}
.create_calendar_widget .modal_footer .modal_btn {
    font-family: var(--poppins);
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin: 10px;
    border: none;
    height: 50px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}
.create_calendar_widget .modal_footer .create_btn {
    background: var(--accent-color) !important;
    width: 184px;
    color: #fff;
}
.create_calendar_widget .modal_footer .closs_btn {
    background: #FF264A;
    width: 108px;
    color: #fff;
}

.calendar_section .page_content > .row {
    margin-left: -10px;
    margin-bottom: 0;
    margin-right: -10px;
}
.main__content_wrapper {
    /* overflow: auto;
    overflow-x: hidden; */
}
.calendar_section .page_content {
    /* height: calc(100vh - 220px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 15px;
    padding-bottom: 0;
    padding-right: 15px;
    margin-right: -20px; */
    overflow-y: auto;
    overflow-x: hidden;
}
.calendar_section .calendar_card {
    border-radius: 10px;
    margin: 10px 0;
    min-height: 20px;
    background: #fff;
}
.calendar_section .card_heading {
    border-bottom: 1px solid rgba(229, 232, 239, 0.5);
    padding: 20px;
    position: relative;
}
.calendar_section .card_heading h5 {
    line-height: 30px;
    font-size: 16px;
    margin: 0;
    color: #133159;
    font-weight: 500;
    text-transform: capitalize;
}
.calendar_section .card_heading p {
    color: rgba(19, 49, 89, 0.65);
    line-height: 24px;
    font-size: 16px;
    margin: 0 0 5px;
}
.calendar_section .card_heading .dot_btn {
    position: absolute;
    right: 20px;
    top: 15px;
}
.calendar_section .card_heading .dropdown-content {
    box-shadow: 0 8px 24px rgb(0 0 0 / 12%);
    left: inherit !important;
    width: auto !important;
    right: 50px !important;
    top: 10px !important;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
}
.calendar_section .card_heading .dropdown-content li:hover {background: #EFEFEF;}
.calendar_section .card_heading .dropdown-content li {
    line-height: inherit;
    min-height: auto;
    transition: all .2s;
}
.calendar_section .card_heading .dropdown-content li:first-child a {padding-top: 18px;}
.calendar_section .card_heading .dropdown-content li:last-child a {
    padding-bottom: 16px;
    border: none;
}
.calendar_section .card_heading .dropdown-content a svg {
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -6px;
    display: inline-block;
}
.calendar_section .card_heading .dropdown-content a {
    border-bottom: 1px solid rgba(19, 49, 89, 0.10);
    line-height: 30px;
    font-size: 16px;
    min-width: 134px;
    padding: 15px;
}
.calendar_section .card_heading .eidt {color: rgba(19, 49, 89, 0.65);}
.calendar_section .card_heading .delete {color: #FF264A}
.calendar_section .card_body {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 15px 25px;
}
.calendar_section .switch_btn .action {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 30px;
}
.calendar_section .switch_btn span.on {display: inline-block;}
.calendar_section .switch_btn span.off {display: none;}
.calendar_section .switch_btn span {color: #3C7EF3;}
.calendar_section .switch_btn {
    align-items: center;
    display: flex;
    padding: 15px 20px;
}
.calendar_section .switch_btn svg {
    height: 25px;
    width: 50px;
}
.calendar_section .switch {
    display: inline-block;
    position: relative;
    margin-left: 12px;
    width: 50px;
    height: 25px;
    cursor: pointer;
}
.calendar_section .switch [type="checkbox"]:checked + span + small {display: none;}
.calendar_section .switch input { 
  	opacity: 0;
  	width: 0;
  	height: 0;
}
.calendar_section .switch .slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}
.calendar_section .switch .slider:before {display: none;}
.calendar_section .switch .slider.round {
    border-radius: 34px;
    height: 30px;
    background: none;
}
.calendar_section .switch .slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}
.calendar_section .switch .on {
    color: #fff;
    left: 4px;
}
.calendar_section .switch .on,
.calendar_section .switch .off {
    position: absolute;
    height: 32px;
    top: 0;
    left: 0;
    z-index: 9;
    width: 60px;
}
.calendar_section .switch .off {
    color: #9CA8BA;
    right: 5px;
}
.calendar_section .card_body .link {
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
    overflow: hidden;
    font-size: 14px;
    color: #3C7EF3;
}
.calendar_section .card_body .footer_action {display: flex; align-items: center;}
.calendar_section .card_body .footer_action a {
    background: #F6F7FB;
    white-space: nowrap;
    border-radius: 5px;
    line-height: 24px;
    margin-left: 15px;
    min-width: 136px;
    color: #3C7EF3;
    height: 40px;
    display: flex;
    font-size: 14px;
    padding: 5px 15px;
    align-items: center;
}
.calendar_section .card_body .footer_action a span {margin-left: 10px;}
.calendar_section .card_body .footer_action a.turn_on {
    border: 1px solid #3C7EF3;
    min-width: 80px;
    width: auto;
    background: none;
}

.call_bridge_model .activity--modal-inner textarea {
        height: 80px;
}

.call_bridge_model .activity--modal-inner textarea:focus, .call_bridge_model .activity--modal-inner input:focus {
        outline: 0;
        border: 0 !important;
        box-shadow: unset !important;
}

.call_bridge_model .activity--modal-inner textarea, .call_bridge_model .activity--modal-inner input {
        border: 0 !important;
}

/* calendar section end */

@media screen and (max-width: 1900px) {
    .calendar_section .card_body .footer_action a span {display: none;}
    .calendar_section .card_body .footer_action a {
        justify-content: center;
        min-width: auto;
        padding: 5px;
        width: 40px;
    }
}
@media screen and (max-width: 1200px) {
    .calendar_section .page_heading h4 {
        line-height: 24px;
        font-size: 16px;
    }
    .calendar_section .page_heading a {
        min-width: 180px;
        height: 40px;
        font-size: 12px;
    }
    .calendar_section .card_body .footer_action {
        justify-content: center;
        width: 100%;
    }
    .calendar_section .card_body {
        justify-content: center;
        flex-wrap: wrap;
    }
    .calendar_section .card_heading h5 {
        line-height: 20px;
        font-size: 12px;
    }
    .calendar_section .card_body .link,
    .calendar_section .card_heading p {
        line-height: 18px;
        font-size: 10px;
    }
    .calendar_section .card_body .footer_action a.turn_on {height: 34px;}
    .calendar_section .card_body .footer_action a {
        margin: 10px 10px 0;
        width: 30px;
        padding: 0;
        height: 30px;
        font-size: 12px;
        justify-content: center;
    }
    .calendar_section .card_body .footer_action a svg {
        height: 12px;
        width: 12px;
    }
    .calendar_section .card_heading .dropdown-content a {padding: 12px 15px;}
    .calendar_section .card_heading .dropdown-content a,
    .calendar_section .switch_btn .action {
        line-height: 18px;
        font-size: 12px;
    }
    .calendar_section .switch_btn {padding: 12px 15px;}
    .calendar_section .card_heading .delete svg {
        margin-right: 10px;
        width: 10px;
    }
    .calendar_section .card_heading .eidt svg {
        margin-right: 10px;
        width: 12px;
    }
}

@media screen and (max-width: 991px) {
    .create_calendar_widget .modal_article input {max-width: 200px;}
    .create_calendar_widget .modal_footer .create_btn {
        height: 40px;
        width: 151px;
    }
    .create_calendar_widget .modal_footer .closs_btn {
        height: 40px;
        width: 93px;
    }
    .create_calendar_widget .header h5 {
        line-height: 24px;
        font-size: 16px;
    }
    .create_calendar_widget .modal_footer .modal_btn,
    .create_calendar_widget .modal_article input,
    .create_calendar_widget .modal_article label {
        font-size: 12px;
    }
}

.massaging_service {
    max-width: 1170px;
    background: var(--gray_light);
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 6px 15px rgb(0 0 0 / 10%);
    left: unset;
    top: 80px !important;
    right: 0 !important;
    position: fixed;
    max-height: 80%;
    overflow-y: auto;
}
.call_bridge_model:focus-visible{
    outline: none;
} 
.massaging_service_title {
    border-bottom: 2px solid var(--white);
}

.sub_title h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
    margin-left: 10px;
}

.share_campaign_cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}

.radius-5 {
    border-radius: 5px !important;
}

.add_task_top_buttons a svg {
    fill: var(--gray);
    transition: 0.3s linear;
}

.active-activity-state {
    background-color: var(--accent-color) !important;
    color: #FFFFFF !important;
}
.add_task_top_buttons a:hover {
    background: var(--accent-color) !important;
    color: var(--white) !important;
}
.add_task_top_buttons a:hover svg {
    fill: var(--white);
}
select {
    display: unset;
}

.massage_send_close_button_daterange .send_massage {
    background: var(--accent-color) !important;
    border-radius: 5px;
    padding: 13px 30px;
    color: var(--white);
    margin-right: 30px;
    text-transform: capitalize;
    font-size: 16px;
}
.massage_send_close_button_daterange .send_massage  span{
    padding-right: 10px;
    display: flex;
}

.close_btn {
    background: var(--red);
    padding: 13px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--white);
    border-radius: 5px;
}

.timepicker-wrapper {
    width: 205px !important;
}

.calendar_widget_time_interval_modal-overlay {
    position: absolute !important;
    left: -20px;
    top: -20px;
    width: calc(100% + 40px);
}
.calendar_widget_time_interval_modal .modal-content {padding: 0 !important;}
.calendar_widget_time_interval_modal {
    border-radius: 10px 0 0 10px;
    top: 80px !important;
    right: 0 !important;
    max-width: 720px;
    width: 100%;
    padding: 0;
    left: auto;
    background: #fff;
}
.calendar_widget_time_interval_modal .header {
    border-bottom: 1px solid #E5E8EF;
    justify-content: space-between;
    padding: 20px 20px 12px;
    display: flex;
}
.calendar_widget_time_interval_modal .header h5 {
    line-height: 36px;
    color: #133159;
    font-weight: 500;
    margin: 0;
    font-size: 24px;
}
.edit-availability-tab-modal .slot-header-border{
    border-bottom: 1px solid #E5E8EF;
}
.edit-availability-tab-modal .modal_article{
    border-bottom: none!important;
}
.edit-availability-tab-modal .calendar_widget_time_interval_modal {
    top: 20px!important;
    overflow-y: auto;
    max-height: unset;
    height: auto;
}  
.edit-availability-tab-modal .tab-calendar-modal{
    top: 20px!important;
    overflow-y: auto;
    max-height: initial;
}
.edit-availability-tab-modal .tab-calendar-modal .m-portlet {
    -webkit-box-shadow: 0 1px 15px 1px rgb(69 65 78 / 8%);
    -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    box-shadow: 0 1px 15px 1px rgb(69 65 78 / 8%);
    background-color: #fff;
    border-radius: 8px;
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
}
.edit-availability-tab-modal .tab-calendar-modal .massage_send_close_button{
  padding-top: 30px;
  padding-bottom: 20px;
}
.edit-availability-tab-modal  .massage_send_close_button a{
    transition: all 0.5s ease-in-out;
}
.edit-availability-tab-modal  .massage_send_close_button a:first-child{
    background-color: white;
    border: 1px solid var(--accent-color) !important;
    padding: 11px;
    border-radius: 8px;
    color: white;
    display: flex;
    align-items: center;
}
.edit-availability-tab-modal  .massage_send_close_button a:hover:first-child{
    background-color: var(--light_blue);
    color: white;
}
.edit-availability-tab-modal  .massage_send_close_button a:hover:first-child svg path{
  fill: white;
}
.w-100{
    width: 100%;
}
.edit-availability-tab-modal .tab-calendar-modal .m-checkbox-list{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 50px;
}
.edit-availability-tab-modal .tab-calendar-modal .m-checkbox-list label{
    display: flex;
    align-items: center;
   
}
.edit-availability-tab-modal  .timepicker-wrapper .outside_container {
    position: absolute;
    top: -87px;
}
.calendar_widget_time_interval_modal .modal_article > .row {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: 0;
}
.calendar_widget_time_interval_modal .modal_article {
    border-bottom: 1px solid #E5E8EF;
    padding: 20px;
}
.calendar_widget_time_interval_modal .modal_article label {
    line-height: 24px;
    font-size: 16px;
    color: #133159;
    margin: 0;
    font-weight: 500;
}
.calendar_widget_time_interval_modal .modal_article input:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #E5E8EF;
}
.calendar_widget_time_interval_modal .modal_article input::placeholder {color: #A6ACBE;}
.calendar_widget_time_interval_modal .modal_article input {
    border: 1px solid #E5E8EF;
    font-family: var(--poppins);
    line-height: 40px;
    padding: 8px 20px;
    max-width: 210px;
    height: 40px;
    width: 100%;
    margin: 10px 0;
    font-size: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    color: rgba(19, 49, 89, 0.65);
}
.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content{
    padding-bottom: 20px;
}
.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content select{
    border-radius: 5px;
}
.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content .DateRangePicker  input{
    border: none;
    padding: 0px 5px;
    margin: 0;
}.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content .DateRangePicker  .DateRangePickerInput {
    border-radius: 5px;
    overflow: hidden;
}
.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content .DateRangePicker  .DateRangePicker_picker {
    top: -165px !important;
}
.calendar_widget_time_interval_modal .modal_article.tab-availability-modal-content{}
.calendar_widget_time_interval_modal .modal_footer {
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    display: flex;
}
.calendar_widget_time_interval_modal .modal_footer .modal_btn svg {
    vertical-align: middle;
    margin: -3px 5px 0 0;
}
.calendar_widget_time_interval_modal .modal_footer .modal_btn {
    font-family: var(--poppins);
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin: 10px;
    border: none;
    height: 50px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}
.calendar_widget_time_interval_modal .modal_footer .create_btn {
    background: #3C7EF3;
    width: 184px;
    color: #fff;
}
.calendar_widget_time_interval_modal .modal_footer .closs_btn {
    background: #FF264A;
    width: 108px;
    color: #fff;
}

@media screen and (max-width: 991px) {
    .calendar_widget_time_interval_modal .modal_article input {max-width: 200px;}
    .calendar_widget_time_interval_modal .modal_footer .create_btn {
        height: 40px;
        width: 151px;
    }
    .calendar_widget_time_interval_modal .modal_footer .closs_btn {
        height: 40px;
        width: 93px;
    }
    .calendar_widget_time_interval_modal .header h5 {
        line-height: 24px;
        font-size: 16px;
    }
    .calendar_widget_time_interval_modal .modal_footer .modal_btn,
    .calendar_widget_time_interval_modal .modal_article input,
    .calendar_widget_time_interval_modal .modal_article label {
        font-size: 12px;
    }
}

.preview_container {
    left: 45% !important;
}


.add_task_top_buttons a.active-activity-state svg {
    fill: var(--white);
}

.call_bridge_model .activity--modal-inner input[type=text],
.call_bridge_model .activity--modal-inner input,
.call_bridge_model .activity--modal-inner  textarea,
.call_bridge_model .activity--modal-inner  select{
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #546376;
    background: #FFFFFF;
    border: 1px solid #E5E8EF;
    border-radius: 5px;

}
.call_bridge_model .activity--modal-inner input[type="checkbox"]{

}
.call_bridge_model .activity--modal-inner  textarea{
    line-height: 3rem;
}

.call_bridge_model .activity--modal-inner input[type="checkbox"].filled-in:checked+span:not(.lever):after {
    
}

.call_bridge_model .activity--modal-inner [type="checkbox"] + span:after{
    width: 25px!important;
    height: 25px!important;
}
.call_bridge_model .activity--modal-inner input[type="checkbox"].filled-in:checked+span:not(.lever):before{
    top: 2px;
    left: 3px;
}

.calendar-widget-dropdown .MuiMenu-paper{
    width: 200px;
    background: #FFFFFF;
    border: 2px solid rgba(19, 49, 89, 0.06);
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}
.calendar-widget-dropdown ul {
    padding: 0px;
}
.calendar-widget-dropdown ul li{
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 0.5px solid rgba(19, 49, 89, 0.35);
}
.calendar-widget-dropdown ul li:last-child{
    border: 0px;
}
.calendar-widget-dropdown ul li .MuiListItemIcon-root{
    min-width: 20px;
    height: 20px;
    margin-right: 15px;
}
.calendar-widget-dropdown ul li .MuiListItemText-inset{
    padding: 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
}
.calendar-widget-dropdown ul li .MuiListItemIcon-root svg path{
    stroke-opacity: 1;
}
.calendar-widget-dropdown ul li:hover .MuiListItemText-inset {
    color: var(--red);
}
.calendar-widget-dropdown ul li:first-child .MuiListItemIcon-root svg path{
   stroke: var(--red);
}
.calendar-widget-dropdown ul li:hover .MuiListItemIcon-root svg path{
   fill: var(--red);
  
}

.massaging_service.call_bridge_model.awesome__scroll_bar{
    padding-bottom: 0;
    overflow-x: hidden;
}

textarea.code-snippet{
    height: 8rem !important;
}

.custom-loader-size{
    width: 22px !important;
    height: 22px !important;
}

.custom-cursor-pointer{
    cursor: pointer;
}

.event__location .MuiIconButton-root {
    width: 20px;
}

.event__location .MuiTypography-root {
    margin-left: 19px;
}
.event__location .Mui-checked .MuiIconButton-label .jss7 svg + svg {
    top: 0px;
    left: -1px;
}

.modal-back {
    justify-content: center;
    background: #F6F7FB;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 115px;
    padding: 0 12px;
    height: 50px;
    color: #3C7EF3;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    border: 1px solid #E5E8EF;
    width: 15%;
}

.apply-btn{
    border-top: 1px solid #E5E8EF;
    border-radius: 5px;
    padding: 7px 20px;
    height: 50px;
    box-shadow: none;
    margin: 0 0 0 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: inherit;
}
.apply-btn.alt{
    display: inline-flex;
    align-items: center;
    background: #3C7EF3;
}
.apply-btn.alt:hover {
    box-shadow: unset;
}

.close_btn.alt span,
.apply-btn.alt span {
    max-height: 22.5px;
}
.close_btn.alt span {
    margin-right: 0.5rem !important;
}
.close_btn.alt {
    display: inline-flex;
    align-items: center;
}

.btnSetHeight span {
    max-height: 22.5px;
}

.modal_initial {
    overflow: initial;
}

.create_calendar_widget .react-datepicker__day-name, .create_calendar_widget .react-datepicker__day {
    width: 35px;
    line-height: 35px;
    font-size: 14px;
    color: #666a73;
}

.menu-item-bg-color .MuiMenuItem-root{
    color: var(--accent-color) !important;
}

.footer_action.m-calenderfooter-action-alt{
    justify-content: space-between;
    width: 100%;
}

.calendar_section .card_body {
    min-height: 70px;
}

.calendar_block_header .make_default_calendar_btn{
    justify-content: center;
    background: #F1A134 !important;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 180px;
    padding: 0 10px;
    height: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.calendar_block_header .default_calendar{
    justify-content: center;
    background: var(--accent-color) !important;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 180px;
    padding: 0 10px;
    height: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}


/* 01/08/2022 */
.CalendarModule-container-parent-class .input_group_calender_widget_wr{
    display: flex;
    grid-gap: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.CalendarModule-container-parent-class .input_group_calender_widget_wr .input_group_calender_widget_wr_title{
    margin-bottom: 0 !important;
}
.CalendarModule-container-parent-class .input_group_calender_widget_container{
    display: flex;
    align-items: center;
    grid-gap: 12px;
}
.CalendarModule-container-parent-class .input_group_calender_widget_wr .input_group_calender_widget_btn{
    background: var(--dark_blue);
    color: #fff;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 4px;
}
.CalendarModule-container-parent-class .input_group_calender_widget_wr .custom_menu_option_container{
    font-size: 14px !important;
}

.CalendarModule-container-parent-class .timing_wedgets_wr_v2 .input_group_calender_widget_row_single{
    margin-bottom: 16px;
    border-radius: 4px;
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.12);
    padding: 24px 16px 8px 16px;
}

.CalendarModule-container-parent-class .timing_wedgets_wr_v2 .input_group_calender_widget_row_single:first-child{
    /* box-shadow: 0px 0 0px rgba(0, 0, 0, 0.0) !important;
    padding: 0 !important;  */
}

.CalendarModule-container-parent-class .timing_wedgets_wr_v2 .input_group_calender_widget_row_single .select select{
    height: 40px !important;
}
.CalendarModule-container-parent-class .policy_list.policy_list_for_extra_v2{
    align-items: flex-start !important;
    justify-content: space-between;
}
.CalendarModule-container-parent-class .timing_wedgets_wr_v2 .timing_wed_add_another_reminder_btn{
    background: var(--dark_blue);
    font-size: 14px;
    color: #fff;
    padding: 7px 8px;
    border-radius: 4px;
}

.CalendarModule-container-parent-class .calender_confirmation_widget select{
    height: 40px !important;
}

/* new css will be here */
.calendar__widget__new__design_edit .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd,
.calendar__widget__new__design_new .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd{
    border: 1px solid #E5E8EF !important;
}
.calendar__widget__new__design_edit .MuiButtonBase-root.MuiIconButton-root{
    position: absolute;
    right: 10px;
}
.calendar__widget__new__design_new .MuiButtonBase-root.MuiIconButton-root{
    position: absolute;
    right: 10px;
}
.calendar_card_new_v2h_top_scroll{
    height: calc(100vh - 195px) !important
}

.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-row-gap: 24px;
    grid-gap: 24px;
    align-content: flex-start;
    justify-content: center;
}
.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .calendar_card{
    height: 100%;
    margin: 0;
}

.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .card_body a{
    display: flex;
    grid-gap: 5px;
    font-size: 14px;
}
.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .card_body a svg{
    width: 14px;
}
.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .card_body .footer_action{
    display: flex;
    align-items: center;
    grid-gap: 8px;
   
}
.CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .card_body .footer_action a{
    margin: 0 !important;
}





@media screen and (max-width:1200px) {
    .CalendarModule-container-parent-class .calendar_card_new_v2h_wrapper .card_body .footer_action{
        margin-top: 8px;
    }
    .calendar_card_new_v2h_wrapper .calendar_block_header .make_default_calendar_btn{
        min-width: unset !important;
        height: unset !important;
        padding: 5px !important;
    }
}

@media screen and (max-width:991px) {
    .calendar_card_new_v2h_wrapper{
        grid-template-columns: repeat(2,1fr) !important;
        grid-row-gap: 16px;
    }
    .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .card_heading{
        padding: 15px 15px 9px 15px;
    }
    .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .card_heading .heading-top {
        flex-direction: column;
    }
    .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .calendar_card_new_lv_icon{
        flex-direction: row;
        justify-content: space-between;
        padding-top: 8px;
    }
    .calendar_card_new_v2h_wrapper .calendar_block_header .make_default_calendar_btn{
        display: inline-block;
    }
    .calendar_card_new_v2h_wrapper .calendar_card_new_v2h .heading_action .dropdown-trigger svg{
        height: 20px;
    }
    .calendar_card_new_v2h_wrapper .calendar_block_header .make_default_calendar_btn{
       
        margin-bottom: 4px;
    }
}

@media screen and (max-width:575px) {
    .calendar_card_new_v2h_wrapper{
        grid-template-columns: repeat(1,1fr) !important;
    }
    /* .calendar_card_new_v2h_wrapper .calendar_block_header .make_default_calendar_btn{
        padding: 5px !important;
    } */
}

.CalendarModule-container-parent-class {
    padding-right: 10px;
    height: 100%;
    overflow-y: auto;
}
.CalendarModule-container-parent-class::-webkit-scrollbar {
    width: 8px;
}
.CalendarModule-container-parent-class::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: var(--purple);
}
.CalendarModule-container-parent-class::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: var(--dark_blue);
}