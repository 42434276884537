.rbc-calendar {
    min-height: 900px;
}

.m-widget4 .m-widget4__item .m-widget4__info .task-date{
    font-size: 0.8rem;
}

.m-widget4 .m-widget4__item .m-widget4__ext .task-time{
    font-size: 0.8rem;
}
/*#activity-calender{*/
    /*margin: -2.2rem -2.2rem 0;*/
/*}*/
#activity-calender .react-tabs{
    border-top: 0;
    max-height: 900px;
    overflow: hidden;
}
#activity-calender .react-tabs .react-tabs-header{
    background-color: #f9f9f9;
}
#activity-calender .react-tabs .react-tabs-header .react-tabs__tab-list{
    padding: 0 0.8rem 0 2.2rem;
}
#activity-calender .react-tabs-body {
    max-height: 830px;
    overflow-y: auto;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__img.m-widget4__img--logo{
    padding-right: 8px;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__img.m-widget4__img--logo img{
    width: 40px;
    height: 40px;
}
#activity-calender .m-widget4 .m-widget4__item{
    padding-left: 2.2rem;
    padding-right: 7px;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__info > div{
    paddingBbottom: 3px;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__info i{
    color: #603ccf;
    display: inline-block;
    vertical-align: middle;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__info .time span{
    display: inline-block;
    vertical-align: middle;
}
#activity-calender .rbc-calendar .rbc-toolbar{
    height: 70px;
    border-bottom: 1px solid #ebedf2;
    padding: 0 20px;
}
#activity-calender .m-widget4 .m-widget4__item .m-widget4__info .m-widget4__title:hover{
    text-decoration: none;
}
#activity-calender .rbc-calendar > div:last-child{
    margin: 20px 20px 0;
    width: auto;
}
#activity-calender .rbc-calendar  .rbc-header{
    padding: 5px;
}
#activity-calender .rbc-calendar .rbc-event{
    border-radius: 3px;
    color: #603ccf;
    margin: 2px 6px;
    font-size: 11px;
    border: 1px solid #603ccf;
    font-weight: 500;
    background: #f7f7fb;
    text-align: center;
}
#activity-calender .rbc-show-more{
    text-align: center;
}
#activity-calender .rbc-date-cell{
    padding: 5px;
}
#activity-calender .rbc-toolbar button{
    font-size: 14px;
    font-weight: 500;
    border-color: #ebedf2;
    color: #777;
}
#activity-calender .rbc-toolbar button:focus{
    outline: none;
}
#activity-calender .rbc-toolbar .rbc-btn-group button:first-child,#activity-calender .rbc-toolbar .rbc-btn-group button:last-child{
    background: #f9f9f9;
}

.timepicker-wrapper .time_picker_modal_container {
    bottom: auto !important;
    width: auto;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.2);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.timepicker-wrapper .react_times_button{
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #e2e2e2;
}
.calendar_widget_time_interval_modal .tab-modal-input .btn{
   box-shadow: none;
   padding:6.5px ;
   height: unset;
   border-radius: 8px;
}
.calendar_widget_time_interval_modal .tab-modal-input a.btn{
    background-color: white;
    border: 1px solid var(--accent-color) !important;;
    color: var(--accent-color) !important;
    transition: all 0.5s ease;
}
.calendar_widget_time_interval_modal .tab-modal-input a.btn:hover{
    background-color:var(--accent-color) !important;
    color: white !important;
}
.delete-picker{
    background-color: var(--red);
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.delete-picker svg{
    fill: white;
}
/* .rbc-event {
    background-color: #ffffff !important;
    color: #7c7979 !important;
    border : #7c7979 1px solid !important;
} */

.custom-pointer {
    cursor: pointer;
    color: blue !important;
}

.custom-pointer-list {
    cursor: pointer;
}

.rbc-toolbar-label{
    text-align:right !important;
}

.date-in-past {
    width: 14.3%;
    background: #f1f2f7;
    border-right: solid 1px #fff;
}

.date-in-future {
    width: 14.3%;
    border-right: solid 1px #ccc;
}
.rbc-month-view{
    height:auto !important;
}

