body {background: #f4f5f6 !important;}
/* calendar section start */
/* .main__content {width: 100%;} */
.calendar_section {
    /* min-height: calc(100vh - 120px);
    margin: 5px 0 20px;
    background: #fff;
    /* overflow: hidden; */
    
}
.calendar_section .page_heading {
    border-bottom: 1px solid #E5E8EF;
    justify-content: space-between;
    background: #fff;
    display: flex;
    padding: 12px 20px;
    flex-wrap: wrap;
    align-items: center;
}
.calendar_section .page_heading h4 span {
    font-weight: 600;
    color: #3C7EF3;
}
.calendar_section .page_heading h4 {
    margin: 0 0 0 25px;
    color: #133159;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
}
.calendar_section .page_heading .action,
.calendar_section .page_heading .title {
    align-items: center;
    display: flex;
}
.calendar_section .page_heading a.back {
    justify-content: center;
    background: #F6F7FB;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 115px;
    padding: 0 12px;
    height: 50px;
    width: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    border: 1px solid #E5E8EF;
}
.calendar_section .page_heading a span {margin-left: 8px;}
.calendar_section .page_heading .action p span {color: #3C7EF3;}
.calendar_section .page_heading .action p {
    color: rgba(19, 49, 89, 0.65);
    margin-right: 15px;
    line-height: 30px;
    font-size: 16px;
}
.calendar_section .page_heading a.view {
    justify-content: center;
    background: #F6F7FB;
    align-items: center;
    border-radius: 5px;
    display: flex;
    min-width: 170px;
    padding: 0 12px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    margin-left: 20px;
    border: 1px solid #E5E8EF;
}
.calendar_section .switch {
    display: inline-block;
    position: relative;
    width: 62px;
    height: 32px;
    cursor: pointer;
}
.calendar_section .switch [type="checkbox"]:checked + span + small {display: none;}
.calendar_section .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.calendar_section .switch .slider {
    position: absolute;
    transition: .4s;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
}
.calendar_section .switch .slider:before {display: none;}
.calendar_section .switch .slider.round {
    border-radius: 34px;
    height: 30px;
    background: none;
}
.calendar_section .switch .slider.round:before {
    border-radius: 50%;
    border: none;
    top: 1px;
    left: 5px;
    width: 22px;
    z-index: 99;
    height: 22px;
    background: #9CA8BA;
}
.calendar_section .switch .on {
    color: #fff;
    left: 4px;
}
.calendar_section .switch .on,
.calendar_section .switch .off {
    position: absolute;
    height: 32px;
    top: 0;
    left: 0;
    z-index: 9;
    width: 60px;
}
.calendar_section .switch .off {
    color: #9CA8BA;
    right: 5px;
}
.calendar_section .page_content {padding: 15px 0px 0;}
.calendar_section .page_content .title_alart {
    justify-content: space-between ;
    display: flex;
    margin: 7px 0 25px;
    align-items: center;
}
.calendar_section .page_content .title_alart h6 {
    line-height: 30px;
    color: #FF264A;
    font-size: 16px;
    margin: 0;
    max-width: 75%;
    font-weight: 400;
    padding-right: 20px;
}
.calendar_section .page_content .title_alart p {
    line-height: 30px;
    color: #133159;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

.calendar_section .page_content .collapsible {
    /*height: calc(100vh - 270px);*/
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 15px;
    padding-bottom: 0;
    padding-right: 26px;
    margin-right: -40px;
    box-shadow: none;
    background: none;
    border: none;
    margin-bottom: 0;
}
.calendar_section .page_content .collapsible > li.active .collapsible-header svg {transform: rotate(-180deg);}
.calendar_section .page_content .collapsible > li.active {border-radius: 10px;}
.calendar_section .page_content .collapsible-header svg {
    transition: all .2s;
    margin-top: 8px;
}
.calendar_section .page_content .collapsible-header {
    justify-content: space-between;
    background: none;
    padding: 15px 20px;
    display: flex;
    align-items: flex-start;
}
.calendar_section .page_content .collapsible > li {
    border-radius: 10px 10px 0 0;
    margin-bottom: 20px;
    overflow: hidden;
    background: #F5F7FB;
}
.calendar_section .page_content .collapsible-header h5 {
    font-weight: 500;
    font-size: 16px;
    margin: 5px 0 0;
    color: #133159;
}
.calendar_section .page_content .collapsible-header p {
    color: rgba(19, 49, 89, 0.65);
    margin: 5px 0 0;
    font-size: 15px;
}
.calendar_section .page_content .collapsible-body .row {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}
.calendar_section .page_content .collapsible-body {
    border: none;
    padding: 0;
}
body#tinymce {background: #fff !important;}
.calendar_section .page_content .radio_input {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
}
.calendar_section .page_content .radio_input label {margin-right: 15px;}
.calendar_section .page_content .radio_input span {
    color: rgba(19, 49, 89, 0.65);
    padding-left: 25px;
    user-select: none;
    font-size: 14px;
}
.calendar_section .page_content .radio_input input[type="radio"]+span:before {
    border: 1px solid #3C7EF3;
    height: 18px;
    width: 18px;
    margin: 4px 0;
}
.calendar_section .page_content .radio_input input[type="radio"]+span:after {
    transform: scale(1) !important;
    width: 12px !important;
    height: 12px !important;
    left: -1px !important;
    top: 3px !important;
    border: none;
}
.calendar_section .page_content .color_radio [type="radio"]:checked+span+svg {display: inline-block;}
.calendar_section .page_content .color_radio label svg {
    position: absolute;
    top: 11px;
    left: 6px;
    display: none;
}
.calendar_section .page_content .color_radio label {position: relative;}
.calendar_section .page_content .color_radio [type="radio"].with-gap:checked+span:before {background: none;}
.calendar_section .page_content .radio_input input[type="radio"]+span:after {background: #3C7EF3;}
.calendar_section .page_content .color_radio {
    position: relative;
    display: flex;
}
.calendar_section .page_content .color_radio .one input[type="radio"]+span:before {
    border: 1px solid #3C7EF3;
    background: #3C7EF3;
}
.calendar_section .page_content .color_radio .one [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .one [type="radio"]:checked+span:after {background: #3C7EF3;}
.calendar_section .page_content .color_radio .two input[type="radio"]+span:before {
    border: 1px solid #133159;
    background: #133159;
}
.calendar_section .page_content .color_radio .two [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .two [type="radio"]:checked+span:after {background: #133159;}
.calendar_section .page_content .color_radio .three input[type="radio"]+span:before {
    border: 1px solid #546376;
    background: #546376;
}
.calendar_section .page_content .color_radio .three [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .three [type="radio"]:checked+span:after {background: #546376;}
.calendar_section .page_content .color_radio .four input[type="radio"]+span:before {
    border: 1px solid #6258FF;
    background: #6258FF;
}
.calendar_section .page_content .color_radio .four [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .four [type="radio"]:checked+span:after {background: #6258FF;}
.calendar_section .page_content .color_radio .five input[type="radio"]+span:before {
    border: 1px solid #68EBCA;
    background: #68EBCA;
}
.calendar_section .page_content .color_radio .five [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .five [type="radio"]:checked+span:after {background: #68EBCA;}
.calendar_section .page_content .color_radio .six input[type="radio"]+span:before {
    border: 1px solid #673ab7;
    background: #673ab7;
}
.calendar_section .page_content .color_radio .six [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .six [type="radio"]:checked+span:after {background: #673ab7;}
.calendar_section .page_content .color_radio .seven input[type="radio"]+span:before {
    border: 1px solid #F2994A;
    background: #F2994A;
}
.calendar_section .page_content .color_radio .seven [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .seven [type="radio"]:checked+span:after {background: #F2994A;}
.calendar_section .page_content .color_radio .eight input[type="radio"]+span:before {
    border: 1px solid #F2C94C;
    background: #F2C94C;
}
.calendar_section .page_content .color_radio .eight [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .eight [type="radio"]:checked+span:after {background: #F2C94C;}
.calendar_section .page_content .color_radio .nine input[type="radio"]+span:before {
    border: 1px solid #219653;
    background: #219653;
}
.calendar_section .page_content .color_radio .nine [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .nine [type="radio"]:checked+span:after {background: #219653;}
.calendar_section .page_content .color_radio .ten input[type="radio"]+span:before {
    border: 1px solid #27AE60;
    background: #27AE60;
}
.calendar_section .page_content .color_radio .ten [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .ten [type="radio"]:checked+span:after {background: #27AE60;}
.calendar_section .page_content .color_radio .eleven input[type="radio"]+span:before {
    border: 1px solid #6FCF97;
    background: #6FCF97;
}
.calendar_section .page_content .color_radio .eleven [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .eleven [type="radio"]:checked+span:after {background: #6FCF97;}
.calendar_section .page_content .color_radio .twelve input[type="radio"]+span:before {
    border: 1px solid #9B51E0;
    background: #9B51E0;
}
.calendar_section .page_content .color_radio .twelve [type="radio"].with-gap:checked+span:after,
.calendar_section .page_content .color_radio .twelve [type="radio"]:checked+span:after {background: #9B51E0;}
.calendar_section .page_content .color_radio input[type="radio"]+span:before {
    margin: 4px 0;
    height: 24px;
    width: 24px;
}
.calendar_section .page_content .color_radio input[type="radio"]+span:after {
    transform: scale(1) !important;
    width: 18px !important;
    height: 18px !important;
    left: -1px !important;
    top: 3px !important;
    border: none;
}
.calendar_section .page_content .tox-tinymce {
    height: 413px !important;
    border-radius: 5px;
    overflow: hidden;
}
.calendar_section .page_content .collapsible_content {padding: 20px;}
.calendar_section .page_content .input_group {margin-bottom: 15px;}
.calendar_section .page_content .option_select {
    position: relative;
    width: 100%;
}
.calendar_section .input_group input:focus {
    border: 1px solid #ADB7C5 !important;
    box-shadow: none !important;
}
.calendar_section .input_group input::placeholder {
    color: rgba(19, 49, 89, 0.65);
}
.calendar_section .input_group input {
    color: rgba(19, 49, 89, 0.65);
    border: 1px solid #ADB7C5;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    margin: 0;
    border-radius: 5px;
    font-size: 14px!important;
    font-weight: normal;
    background: #fff;
    box-sizing: border-box;
    font-family: var(--poppins);
}
.calendar_section .input_group .select-dropdown {width: 100% !important;}
.calendar_section .input_group select,
.calendar_section .input_group textarea,
.calendar_section  select {
    border: 1px solid rgba(29, 35, 43, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    border-radius: 5px;
    font-family: var(--poppins);
}
.calendar_section .input_group select{
    margin-top: 0px;
}
.calendar_section .input_group .dropdown-content li {min-height: auto;}
.calendar_section .input_group .dropdown-content li>a,
.calendar_section .input_group .dropdown-content li>span {
    color: rgba(19, 49, 89, 0.65);
    font-size: 15px;
    padding: 12px;
}
.calendar_section .page_content .option_select svg {
    position: absolute;
    z-index: 9;
    right: 8px;
    top: 10px;
    width: 13px;
    height: 25px;
    background: #fff;
    display: none;
}
.calendar_section .page_content .policy_list {
    background: #fff;
    border-radius: 5px;
    display: flex;
    margin: 10px 0;
    padding: 10px 20px;
    align-items: center;
}
.calendar_section .page_content .policy_list h4{
    font-size: 14px;
}
.calendar_section .policy_list .action .custom_btn {
    border: 1px solid #ADB7C5;
    background: #E0E0E0;
    width: 120px;
    height: 40px;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    font-family: var(--poppins);
    color: rgba(19, 49, 89, 0.65);
}
.calendar_section .policy_list .action .switch {margin: 0 40px 0 0 !important;}
.calendar_section .policy_list .action {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    height: 100%;
    min-width: 310px;
}
.calendar_section .page_content .input_group .link_copy a {
    /* max-width: calc(100% - 160px); */
    line-height: 24px;
    overflow: hidden;
    color: #3C7EF3;
    /* width: 100%; */
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.calendar_section .page_content .input_group .link_copy {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}
.calendar_section .page_content .input_group .link_copy input {
    max-width: 160px;
    width: 100%;
}
.calendar_section .policy_list .action input.select-dropdown,
.calendar_section .input_group .action .dropdown-content li {padding: 0 0 0 45px;}

.calendar_section .policy_list .article p {color: rgba(19, 49, 89, 0.65);}
.calendar_section .page_content .action .option_select svg {right: 45px;}
.calendar_section .page_content .action .input_group {width: 100%;}
.calendar_section .policy_list .article h6 {color: #133159;}
.calendar_section .policy_list .article {width: 50%;}
.calendar_section .policy_list .article h6,
.calendar_section .policy_list .article p {
    line-height: 24px;
    font-size: 16px!important;
    margin: 0;
}
.calendar_section .page_content .input_group label {
    margin-bottom: 5px;
    color: #133159;
    font-size: 16px!important;
    display: block;
    line-height: 24px;
}
.calendar_section .page_content .personalized-confirmation  .input_group label{
        font-size: 18px;
        line-height: 20px;
        font-family: var(--poppins);
}
.calendar_section .page_content .condition_label {
    align-items: center;
    display: flex;
}
.calendar_section .page_content .switch .on,
.calendar_section .page_content .switch .off,
.calendar_section .page_content .switch {
    height: 26px;
    width: 52px;
}
.calendar_section .page_content .switch {margin: -5px 0 0 15px !important}
.calendar_section .page_content .white_body {
    padding: 15px 20px 20px;
    border-radius: 5px;
    background: #fff;
}
.calendar_section .page_content .collapsible .MuiAccordion-root{
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 20px;
    border: none;
    box-shadow: none;
}
.calendar_section .page_content .collapsible .MuiAccordion-root:before{
    display: none;
}
.calendar_section .page_content .collapsible .MuiAccordion-root .MuiAccordionSummary-root::after{
    content:'';
    position:absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #fff;
}
.MuiAccordionSummary-root.Mui-expanded{}
.calendar_section .page_content .collapsible .MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded::after{
    border-color: #E5E8EF;
}
.calendar_section .page_content .collapsible  .accordion__details{
  padding-top: 20px;
}
.calendar_section  .MuiAccordionSummary-content .accordion_header h6{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #133159; 
}
.calendar_section  .MuiAccordionSummary-content .accordion_header span{
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;

    color: rgba(19, 49, 89, 0.65);

}
.calendar_section .page_content .collapsible .MuiAccordion-root .MuiAccordionSummary-content{ 
   
}
.calendar_section .page_content .accordion__event_wrapper{

}
.accordion__event_wrapper .event_sub__title{
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #133159;
}
.accordion__event_wrapper .date-range-edit{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
}
.calendar_widget_time_interval_modal .massage_send_close_button{
  margin:20px 0px
}

.accordion__event_wrapper .event-duration-wrapper ul{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li{
    background: #FFFFFF;
    border: 1px solid #E0E0E0!important;
    border-radius: 5px;
    min-width: 73px!important;
    width: auto!important;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    min-height: 1px;
    height: 40px;  
    color: rgba(19, 49, 89, 0.65);
}
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li .event-duration-title,
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li .event-duration-desc{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
   
}
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li.active {
    background-color: var(--accent-color) !important;
    color: var(--white);
}
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li.active 
.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li:last-child{
    margin: 0;
}

.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li .event-duration-title{
    margin: 0;
    padding-right: 5px;
}

.accordion__event_wrapper .event-duration-wrapper ul.event-duration-items li.event-custom-duration  input[type="number"] {
    border: none;
    width: 40px;
    text-align: inherit;
    font-size: inherit;
    line-height: inherit;
    min-height: 0;
    max-height: 20px;
    padding: 0px;
    vertical-align: bottom;
    word-break: normal;
    outline: none;
    -webkit-appearance: none;
    background: #fff;
    border-radius: 0;
}
.accordion__event_wrapper  .body-small-text{
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);

}
.event-calendar-toolbar .rbc-btn-group{
  position: relative;
  top: 0;
}
.event-calendar-toolbar .rbc-btn-group button{
    margin-right: 10px;
    border: 1px solid #F6F7FB;
    border-radius: 5px;
}
.event-calendar-toolbar .rbc-month-header{
  padding-top: 0px;
}
.event-calendar-toolbar  .rbc-month-view{
    border: 0px;
}
.event-calendar-toolbar .rbc-month-header .rbc-header{
    background: #F6F7FB;
    border-radius: 4.74116px 4.74116px 0px 0px;
    padding: 10px;
    border:none;
    margin-right: 20px;
    text-align: left;
    border-bottom:2px solid #E5E8EF;
}
.event-calendar-toolbar .rbc-date-cell{
  text-align: left;
}
.event-calendar-toolbar .date-in-past{
    background-color: white;
    border-bottom:2px solid #E5E8EF;
    margin-right: 20px;
}
.event-calendar-toolbar .date-in-future{
    border-right: none;
    border-bottom:2px solid #E5E8EF;
    margin-right: 20px;
}
.event-calendar-toolbar .date-in-past:last-child,
.event-calendar-toolbar .date-in-future:last-child{
    margin: 0;
}
.event-calendar-toolbar .rbc-month-row + .rbc-month-row,
.event-calendar-toolbar .rbc-month-row:last-child .date-in-future {
    border: none;
}
.event-calendar-toolbar .rbc-month-row:last-child .date-in-future{
 
}
.event-calendar-toolbar .rbc-date-cell a{
    font-size: 18.9646px;
    line-height: 28px;
    color: #000000; 
}
.event-calendar-toolbar .rbc-month-header .rbc-header:last-child{
    margin: 0px;
}
.event-calendar-toolbar .rbc-month-header .rbc-header span{
    font-style: normal;
    font-weight: normal;
    font-size: 18.9646px;
    line-height: 28px;
    color: #133159;
}
.calendar_section .page_content .collapsible_footer {
    border-top: 1px solid #E5E8EF;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.calendar_section .page_content .collapsible_footer .btn {
    border-top: 1px solid #E5E8EF;
    border-radius: 5px;
    padding: 7px 20px;
    height: 50px;
    box-shadow: none;
    margin: 0 0 0 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: inherit;
}
.calendar_section .page_content .collapsible_footer .btn_info {
    background: #3C7EF3;
    min-width: 345px;
}
.calendar_section .page_content .collapsible_footer .btn_danger {
    background: #FF264A;
}





/* calendar section end */

@media screen and (max-width: 1900px) {
    .calendar_section .page_heading h4 {
        line-height: 24px;
        font-size: 16px;
    }
    .calendar_section .page_content .collapsible-header h5,
    .calendar_section .page_content .collapsible-header p,
    .calendar_section .page_content .title_alart h6,
    .calendar_section .page_content .title_alart p {
        font-size: 14px;
    }
    .calendar_section .page_content .input_group label,
    .calendar_section .page_content .radio_input span,
    .calendar_section .policy_list .article h6,
    .calendar_section .policy_list .article p,
    .calendar_section .input_group input {
        font-size: 12px;
    }
}
@media screen and (max-width: 1366px) {
    .collapsible_content .xl7,
    .collapsible_content .xl5 {width: 50% !important;}
}
@media screen and (max-width: 1280px) {
    .calendar_section .page_heading h4 {margin: 0 0 0 10px;}
    .calendar_section .page_heading .action p,
    .calendar_section .page_heading h4 {font-size: 12px;}
    .calendar_section .page_heading a.view span,
    .calendar_section .page_heading a span {display: none;}
    .calendar_section .page_heading a.back {
        background: none;
        min-width: auto;
        padding: 0;
        border: none;
    }
    .calendar_section .page_heading a.view {
        min-width: 40px;
        padding: 5px;
    }
    .calendar_section .page_content {padding: 12px 10px 0;}
    .calendar_section .page_heading {padding: 12px 10px;}
    .calendar_section .page_content .title_alart {margin: 0 0 15px;}
    .calendar_section .page_content .title_alart h6 {max-width: 60%;}
    .calendar_section .page_content .input_group .link_copy a,
    .calendar_section .page_content .title_alart h6,
    .calendar_section .page_content .title_alart p {
        line-height: 18px;
        font-size: 10px;
    }
    .calendar_section .page_content .collapsible-header svg {width: 12px;}
    .calendar_section .page_content .collapsible-header h5,
    .calendar_section .page_content .collapsible-header p,
    .calendar_section .page_content .input_group label,
    .calendar_section .page_content .radio_input span,
    .calendar_section .policy_list .article h6,
    .calendar_section .policy_list .article p,
    .calendar_section .input_group input {
        line-height: 18px;
        font-size: 12px;
    }

    .calendar_section .page_content .radio_input input[type="radio"]+span:before {
        height: 14px;
        width: 14px;
    }
    .calendar_section .page_content .radio_input input[type="radio"]+span:after {
        width: 8px !important;
        height: 8px !important;
    }
    .calendar_section .page_content .color_radio span {padding-left: 22px;}
    .calendar_section .page_content .color_radio input[type="radio"]+span:before {
        height: 16px;
        width: 16px;
    }
    .calendar_section .page_content .color_radio input[type="radio"]+span:after {
        width: 10px !important;
        height: 10px !important;
    }
    .calendar_section .page_content .color_radio label svg {
        width: 6px;
        top: 7px;
        left: 5px;
    }
    .calendar_section .page_content .input_group .link_copy input {max-width: 130px;}
}

.event-duration-wrapper ul.event-dusration-items {
    padding: 0;
    margin: 0;
}

.event-duration-wrapper ul.event-dusration-items li {
    list-style: none;
    display: inline-block;
    width: 70px;
    border: solid #666a73 1px;
    border-right: none;
    text-align: center;
    color: #666a73;
    font-size: 14px;
    padding: 7px 0px;
}

.event-duration-wrapper ul.event-dusration-items li {
    width: 90px !important;
    border-radius: 4px;
    margin: 0 2px 4px 0;
    border: 2px solid #666a73 !important;
    text-align: center !important;
    min-height: 62px;
    vertical-align: middle;
}

.event-duration-wrapper ul.event-dusration-items li h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
}

.event-duration-wrapper ul.event-dusration-items li h5 {
    text-align: center !important;
}

.event-duration-wrapper ul.event-dusration-items li.event-duration-list.selected {
    border-color: #08be7c !important;
    background-color: #08be7c !important;
    color: #fff;
}

.event-duration-wrapper ul.event-dusration-items li.event-custom-duration input[type="number"] {
    border: none;
    width: 30px;
    text-align: inherit;
    font-size: inherit;
    line-height: inherit;
    min-height: 0;
    max-height: 14px;
    padding: 0px;
    vertical-align: bottom;
    word-break: normal;
    outline: none;
    -webkit-appearance: none;
    background: #fff;
    border-radius: 0;
}
.rbc-event .rbc-event-content .material-icons{
    width: 15px;
    height: 15px;
    font-size: 15px;
    line-height: 19px;
}

.new_calendar_policy_list_update_v2 .collapsible_footer_for_btn_group .collapsible_footer_for_btn2_group svg.MuiSvgIcon-root{
    color: #fff !important;
}


.calender_section_update_v2_h .rbc-calendar{
    width: 100% !important;
  
  
}






@media screen and (max-width:1283px) {
    .calender_section_update_v2_h .accordion__details p.MuiTypography-root{
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .calendar_section .page_heading a svg{
        margin-right: 0 !important;
    }
    .calender_section_update_v2_h .rbc-calendar .rbc-toolbar-label{
        text-align: center !important;
        margin-top: 20px !important;
    }
  
    .new_calendar_policy_list_update_v2 .policy_list{
        flex-direction: column;
        padding: 10px 0 !important;
    }
    .new_calendar_policy_list_update_v2 .new_calendar_policy_list_update_v2_p0{
        padding: 0 !important;
    }
    .new_calendar_policy_list_update_v2 .policy_list .article{
        width: 100% !important;

    }
    .new_calendar_policy_list_update_v2 .policy_list .article p{
        padding: 8px 0 4px 0;
    }
    .new_calendar_policy_list_update_v2 .policy_list .action{
        width: 100% !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-top: 10px;
    }
    .calender_section_update_v2_h .collapsible_footer{
        justify-content: center !important;
        grid-gap: 8px;
    }
    .calender_section_update_v2_h .collapsible_footer .btn{
        padding: 9px 8px !important;
        min-width: unset !important;
        margin: 0 !important;
        height: unset !important;
        justify-content: center;
    }
    .calender_section_update_v2_h .collapsible_footer_extra_btn{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 8px;

    }
    .new_calendar_policy_list_update_v2 .collapsible_footer_for_btn_group {
        justify-content: center !important;
        grid-gap: 8px;
    }
    .new_calendar_policy_list_update_v2 .collapsible_footer_for_btn_group .collapsible_footer_for_btn2_group{
        padding: 4px 8px !important;
        min-width: unset !important;
        margin: 0 !important;
        height: unset !important;
        justify-content: center;
    }
    .calender_section_update_v2_h .calendar_section .page_content .collapsible .accordion__details{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


    /* privacy */
    .white_body_update_white_policy_v2 .white_body_update_white_policy_v2_col{
        width: 100% !important;
    }

   

}
@media screen and (min-width:1024px) and (max-width:1230px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100% !important;
        max-width: 1010px;
        margin: 0 auto;
    }
}

@media screen and (min-width:992px) and (max-width:1023px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100% !important;
        max-width: 991px;
        margin: 0 auto;
    }
}

@media screen and (min-width:768px) and (max-width:991px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100% !important;
        max-width: 676px;
        margin: 0 auto;
    }
   
}




@media screen and (min-width:641px) and (max-width:767px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100%;
        max-width: 559px;
        margin: 0 auto;
     }
}
@media screen and (min-width:576px) and (max-width:640px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100%;
        max-width: 560px;
     }
}
@media screen and (min-width:415px) and (max-width:575px){
    .calender_section_update_v2_h .rbc-calendar{
        width: 100%;
        max-width: 315px;
        margin: 0 auto;
        text-align: center;
     }
}

@media screen and (max-width:412px){
     /* basic */
     .calender_section_update_v2_h .basic_top_row_update_v2{
        width: 100% !important;
     }
}
@media screen and (min-width:391px) and (max-width:414px){
    
    .calender_section_update_v2_h .rbc-calendar{
       width: 100%;
       max-width: 323px;
    }
}
@media screen and (min-width:376px) and (max-width: 390px){
  
    .calender_section_update_v2_h .rbc-calendar{
       width: 100%;
       max-width: 303px;
    }
}

@media screen and (max-width:375px){
  
    .calender_section_update_v2_h .rbc-calendar{
       width: 100%;
       max-width: 286px;
    }
}

@media screen and (max-width:360px){
  
    .calender_section_update_v2_h .rbc-calendar{
       width: 100%;
       max-width: 250px;
    }
}

.calender_section_update_v2_h .sync-email-action {
    color: #133159 !important;
    font-size: 16px !important;
    padding: 0 !important;
    margin: 0 10px 0 0 !important;
}



