.activity_list_table_container {
  overflow-x: auto;
  max-width: 100vw;
}
.activities_items_table {
  background: var(--gray_light);
  border-collapse: separate;
  border-spacing: 0 10px;
  border-radius: 10px;
  padding: 15px 20px;
  white-space: nowrap;
}
.activities_items_table tr td {
  padding: 10px 20px 10px 10px;
}
.activities_items_table tr td:first-child {
  border-radius: 10px 0 0 10px;
}
.activities_items_table tr td:last-child {
  border-radius: 0 10px 10px 0;
}

.activities_items_table p,
.activities_items_table thead label span,
.activities_items_table thead tr td h5 {
  font-size: 16px !important;
}

.activities_items_table tbody label span {
  font-size: 16px !important;
  color: rgba(19, 49, 89, 0.5);
}
.activities_items_table tr {
  border: 1px solid rgba(60, 126, 243, 0.1);
  border-radius: 10px !important;
}

.activities_items_table input[type="checkbox"].filled-in:checked tr {
  background: var(--light_blue) !important;
}
.activities_items_table
  input[type="checkbox"].filled-in
  + span:not(.lever):after {
  border-radius: 5px !important;
  border-color: rgba(60, 127, 243, 0.35) !important;
}
.activities_items_table input[type="checkbox"].filled-in + span:not(.lever) {
  padding-left: 25px;
}
.activities_items_table
  input[type="checkbox"].filled-in:checked
  + span:not(.lever):after {
  background: var(--light_blue) !important;
  border-color: var(--light_blue) !important;
}
.CalendarModule-container-parent-class .activities_items_table .edit_icon_wrapper {
    background: #e5e5e5;
    border-radius: 4px;
    display: inline-flex;
    padding: 6px;
    margin-left: 10px !important;
    min-width: 36px;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s linear;
    color: var(--light_blue);
}

.CalendarModule-container-parent-class .activities_items_table tr:hover .edit_icon_wrapper {
    opacity: 1;
    color: var(--light_blue);
}
.CalendarModule-container-parent-class .alt.activities_items_table.responsive-table tr td:nth-child(2) label {
    width: 200px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	text-transform: capitalize;
    font-weight: 600;
    color: #333;
}
.CalendarModule-container-parent-class .activities_items_table .activity_table_title.alt label span {
	width: 140px;
	color: inherit;
} 
.CalendarModule-container-parent-class .option___container{
  display: flex;
  align-items: center;
}
.CalendarModule-container-parent-class .option___container .action___option___each{
  cursor: pointer;
  margin: 0px 2px;
}

.CalendarModule-container-parent-class .direct-mail-icon-calendar-list {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  margin-top: -3px!important;
  border: 1px solid var(--gray);
  border-radius: 50%;
  /* padding: 5px; */
  cursor: pointer;
  transition: .3s linear;
}
.CalendarModule-container-parent-class .direct-mail-icon-calendar-list svg path {
  fill: #546376;
}


.CalendarModule-container-parent-class .activity_list_table_container .bulk__action__wrapper {
    padding: 10px 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #f6dbeb;
    border-radius: 6px;
    margin-bottom: 10px;
    color: #707070;
}

.CalendarModule-container-parent-class .activity_list_table_container .bulk__action__wrapper span {
    color: #dd2631;
}

.CalendarModule-container-parent-class .activity_list_table_container .bulk__action__wrapper .delete_activity_btn {
    background: var(--red);
    height: 40px;
    width: 175px;
    font-size: 14px;
    font-weight: 700;
    border: none;
}