/*****************************
           Common Style 
  ******************************/
:root {
  --light-blue-10: rgba(60, 126, 243, 0.1);
  --yellow: #f2c94c;
  --yellow-10: rgba(242, 200, 76, 0.1);
  --shade: #f0efff;
  --green: #6fcf97;
  --green-10: rgba(39, 174, 95, 0.1);
  --red-10: rgba(255, 38, 74, 0.1);
  --dark-blue-05: rgba(19, 49, 89, 0.05);
  --dark-blue-10: rgba(19, 49, 89, 0.1);
  --dark-blue-65: rgba(19, 49, 89, 0.65);
}

.text-gray {
  color: var(--gray) !important;
}
.text-light-blue {
  color: var(--light_blue);
}
.text-red {
  color: var(--red);
}
.text-dark-blue {
  color: var(--dark_blue);
}
.text-dark-blue-65 {
  color: var(--dark-blue-65);
}
.text-green {
  color: var(--green);
}
.text-green-2 {
  color: var(--green2);
}
.text-yellow {
  color: var(--yellow);
}
.bg-gray-light {
  background: var(--gray_light);
}
.bg-gray-dark {
  background: var(--gray_dark);
}
.bg-light-blue {
  background: var(--light_blue);
}
.bg-light-blue-10 {
  background: var(--light-blue-10);
}
.bg-dark-blue-10 {
  background: var(--dark-blue-10);
}
.bg-dark-blue-05 {
  background: var(--dark-blue-05);
}
.bg-red {
  background: var(--red);
}
.bg-red-10 {
  background: var(--red-10);
}
.bg-red-65 {
  background: rgba(255, 38, 74, 0.65);
}
.bg-yellow {
  background: var(--yellow);
}
.bg-yellow-10 {
  background: var(--yellow-10);
}
.bg-shade {
  background: var(--shade);
}
.bg-green {
  background: var(--green);
}
.bg-green-10 {
  background: var(--green-10);
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.radius-5 {
  border-radius: 5px !important;
}
.radius-10 {
  border-radius: 10px !important;
}

.massaging_service {
  max-width: 1170px;
  background: var(--gray_light);
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 6px 15px rgb(0 0 0 / 10%);
  left: unset;
  top: 80px !important;
  right: 0 !important;
  position: fixed;
  max-height: 80%;
  overflow-y: auto;
}

.massaging_service_title {
  border-bottom: 2px solid var(--white);
}

.sub_title h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: var(--dark_blue);
  margin: 0;
  margin-left: 10px;
}

.share_campaign_cross_button {
  background: var(--red);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
}

.radius-5 {
  border-radius: 5px !important;
}

.add_task_top_buttons a svg {
  fill: var(--gray);
  transition: 0.3s linear;
}

.active-activity-state {
  background-color: #3c7ef3 !important;
  color: #FFFFFF !important;
}
.add_task_top_buttons a:hover {
  background: var(--light_blue) !important;
  color: var(--white) !important;
}
.add_task_top_buttons a:hover svg {
  fill: var(--white);
}
select {
  display: unset;
}

.massage_send_close_button .send_massage {
  background: #3C7EF3;
  border-radius: 5px;
  padding: 13px 30px;
  color: var(--white);
  margin-right: 30px;
  text-transform: capitalize;
  font-size: 16px;
}

.close_btn {
  background: var(--red);
  padding: 13px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--white);
  border-radius: 5px;
}

.activity__top__header{
  border-bottom: 1px solid darkgrey;
}

.rbc-timeslot-group {
  max-height: 20px;
}

.rbc-month-view{
  background: var(--gray_light);
}

.activity--calendar-wrapper .rbc-month-header .rbc-header, .activity--calendar-wrapper .rbc-time-header-cell .rbc-header
{
  background: var(--gray_light);
}

.activity--calendar-wrapper .rbc-month-view .rbc-day-bg
{
  background: var(--gray_light);
}

.activity--calendar-wrapper .rbc-month-view .rbc-month-row
{
  max-height: 110px;
}

.activity--calendar-wrapper .rbc-calendar {
  min-height: 700px;
}

.activity--calendar-wrapper .rbc-month-header {
  padding: 0;
}

.activity--calendar-wrapper .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  font-size: 15px;
}

.activity--calendar-wrapper .rbc-month-header .rbc-header span, .activity--calendar-wrapper .rbc-time-header-cell .rbc-header a {

  font-size: 15px;
}



/*******************pagenation css area************************/
.CalendarModule-container-parent-class .pagenation-main-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CalendarModule-container-parent-class .pagenation-top form select {
  -webkit-appearance: none;
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 24px;
  color: #133159;
  text-transform: capitalize;
  height: auto;
  text-align: center;
  padding: 5px 43px 5px 10px;
}

.CalendarModule-container-parent-class .pagenation-top {
  position: relative;
  max-width: 160px;
  width: 100%;
  height: 45px 
}
.CalendarModule-container-parent-class .pagenation-top .MuiFormControl-root{
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.CalendarModule-container-parent-class .pagenation-top .MuiFilledInput-root.MuiFilledInput-underline{
  display: flex;
  line-height: unset;
  height: auto;
  align-items: center;
  border-radius: 5px;
}
.CalendarModule-container-parent-class .pagenation-top .MuiFormControl-root .MuiFilledInput-underline:after {
  position: absolute;
  content: "";
  left: auto;
  top: 20px;
  right: 11px;
  background: url(../assets/img/ad.png) no-repeat scroll 0 0 / 100% 100%;
  width: 14px;
  height: 7px;
  transform: none;
  pointer-events: none;
  cursor: pointer;
  z-index: 1;
  border: none;
}
.CalendarModule-container-parent-class .pagenation-top  .MuiFormControl-root svg,
.CalendarModule-container-parent-class .pagenation-top .MuiFilledInput-root.MuiFilledInput-underline:before{
  display: none;
}
.CalendarModule-container-parent-class .pagenation-top .MuiFilledInput-input{
  background: var(--gray_light);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  max-width: 160px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  
}
.CalendarModule-container-parent-class .pagenation-top  input{
 margin: 0;
 padding: 0px;
}
.CalendarModule-container-parent-class .pagenation-top .select-wrapper input:focus{
  border-bottom: unset !important;
}
.CalendarModule-container-parent-class .pagenation-top .select-wrapper  ul li span{
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #133159;
}
.CalendarModule-container-parent-class span.contact-pagenat {
  position: absolute;
  right: -50px;
  top: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #546376;
  letter-spacing: 0.01em;
}
 .CalendarModule-container-parent-class ul.pagination li.arrow-left a i {
  background: #3C7EF3;
  border-radius: 5px;
  color: #fff;
  display: block;
  padding: 10px 10px;
}

.CalendarModule-container-parent-class .pagination li.active {
  background-color: #fff;
  border: 2px solid #3C7EF3;
  box-sizing: border-box;
  border-radius: 5px;
  /* color: #3C7EF3; */
}
.CalendarModule-container-parent-class .pagination li.active a,.CalendarModule-container-parent-class .pagination li.active .pagination--text{
  color: #3C7EF3;
  padding:  0px 8px;
}
.CalendarModule-container-parent-class .pagination li.active .pagination--text {
  height: 44px;
}

 .CalendarModule-container-parent-class .pagination .pagination--text .contact-pagination-no {
  width: 50px;
  border-bottom: none!important;
}

 .CalendarModule-container-parent-class ul.pagination {
  display: flex;
  align-items: center;
  width: 300px;
}

.CalendarModule-container-parent-class .pagination li {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: auto;
  margin-left: -15px; 
}

.CalendarModule-container-parent-class .pagination li a,
.CalendarModule-container-parent-class .pagination li .pagination--text {
  display: inline-block;
  padding: 10px 27px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #3C7EF3;
}

.pagination li a span{

}

.CalendarModule-container-parent-class .activity_list_table_container .activity__loader p {
  text-align: center;
  margin: 100px 0 100px 0;
}



